const projects = [
  "146-160 2nd Street East Condos",
  "Brentwood West Phase 4",
  "Treehouse",
  "22904 Dewdney Trunk Road Condos",
  "Beau",
  "Ardea",
  "14174 64 Avenue Townhomes",
  "7240, 7258 210th Street & 21031, 21081 72nd Avenue Townhomes",
  "2711 Rodgers Creek Place Condos",
  "7100, 7120 Ash Street Townhomes",
  "3685 3695 Victoria Drive Townhomes",
  "Abbey Heights",
  "Aviary Living",
  "Mirada Estates",
  "5860 Granville Avenue Townhomes",
  "6011 River Road Condos",
  "Rowan",
  "King + Crescent (Cedar)",
  "Newbury",
  "Verdure",
  "6908-6968 Ash Street Townhomes",
  "8689 River District Crossing Condos",
  "Ravir",
  "The Collection by BattersbyHowat",
  "6540-6592 Telford Avenue Condos",
  "711 - 729 Dogwood Street Townhomes",
  "Terza",
  "Willow Walk",
  "Sapperton",
  "Burquitlam Plaza",
  "Kinsley",
  "13511 102 Avenue Condos",
  "The Palazzi at Piero Lissoni x Oakridge",
  "Parc Thompson",
  "Bastille",
  "The Passages - Phase 1",
  "Linc",
  "Torino - Pinnacle Living at Capstan Village",
  "3965 North Road Condos",
  "20443 70 Avenue Condos",
  "One20",
  "Format by Cressey",
  "The Fifteen",
  "22936 - 22974 Dewdney Trunk Road Condos",
  "Slate",
  "Vivere by Solterra",
  "10619 138 Street Townhomes",
  "460 Mountain Highway Condos",
  "Hawksley",
  "W68",
  "Slate and Ash",
  "22527 Royal Crescent Condos",
  "Harlowe House",
  "Talistar",
  "Woodcrest Townhomes",
  "Burnaby Lake Village",
  "385 Lakewood Drive Condos",
  "Riviera",
  "Atara II",
  "2484 & 2494 Emerson Street Condos",
  "13227 236 Street Townhomes",
  "13688 Grosvenor Road Condos",
  "Woodland Block",
  "Dunbar at 39th",
  "Rhodes",
  "1690 Kingsway Condos",
  "Whitgift Gardens (Future Phases)",
  "Bailey",
  "Prime One",
  "Rose Garden",
  "2416 Western Avenue Townhomes",
  "19701-19729 55A Avenue Condos",
  "SATORI",
  "Oasis at Concord Brentwood - East Tower",
  "Pier West (East Tower)",
  "Clarendon Heights",
  "1865-1885 Charles Street Condos",
  "Onyx and Ivory",
  "13833 62 Avenue Townhomes",
  "1024-1030 East 20th Avenue Townhomes",
  "2122 East Hasting Street Condos",
  "6815 204 Street Townhomes",
  "3328 Bainbridge Avenue Condos",
  "13111 King George Boulevard Townhomes",
  "9200 - 9340 Francis Road Townhomes",
  "7135 -7157 208 Street Condos",
  "19948 55A Avenue Condos",
  "Westwood",
  "6212 6218 Ash Street Townhomes",
  "Impressions",
  "8393 200 Street Townhomes",
  "9330 178 Street Townhomes",
  "Landmark on Robson - West Tower",
  "6705 & 6731 Willowbrook Connector Townhomes",
  "Times Square",
  "Walker House",
  "Woodside",
  "2211 and 2271 Rosser Avenue Condos",
  "3300 Boundary Road Condos",
  "565-575 Kemsley Avenue Condos",
  "9952 149 Street Condos",
  "19681 80 Avenue Townhomes",
  "2387 & 2399 Atkins Avenue Condos",
  "20130 - 20150 53 Avenue Condos",
  "ACE",
  "9071 Alexandra Road Condos",
  "Walnut Park - Building A",
  "3777 & 3791 Kingsway Condos",
  "20049 82 Avenue Condos",
  "831 Edgar Avenue Townhomes",
  "Revive (Wellside Collection)",
  "Chapter",
  "Alberni Street Towers West Tower",
  "6631 152 Street Townhomes",
  "13534 King George Boulevard Condos",
  "7117 Lindsay Road Townhomes",
  "Oakridge",
  "New Village",
  "Mulgrave Villa",
  "5852 Patterson Avenue Condos",
  "South Yards (Phase 2) ",
  "Ortus",
  "Horseshoe Bay",
  "Jericho - Jericho District (Phase 3)",
  "Amber Gate",
  "Band",
  "157-163 W King Edward Avenue Townhomes",
  "597 Thompson Avenue Townhomes",
  "15316 88 Avenue Condos",
  "Solana",
  "6605 Royal Oak Avenue Condos",
  "3188 Shaughnessy Street Condos",
  "2113 W 32nd Avenue Townhomes",
  "15142 Fraser Highway Condos",
  "Lansdowne - Tower 1",
  "Spring Hill",
  "Fleetwood Orion",
  "Tidal Homes - Townhomes",
  "10227 King George Boulevard Condos",
  "530 Columbia Street - Heritage Revitalization",
  "Element 2",
  "FRÄNK",
  "Chroma",
  "19677 Willowbrook Drive Condos",
  "485 Commercial Drive Townhomes",
  "Southgate Village - Gateway Neighbourhood",
  "1230 Mitchell Street Townhomes",
  "Nuvo",
  "5659 Dunbar Street Condos",
  "10420 240 Street Townhomes",
  "Grafia",
  "Lakewood",
  "19335 70 Avenue Townhomes",
  "Nido",
  "Everwood",
  "17079 80 Avenue Townhomes",
  "17928 92nd Avenue Townhomes",
  "Concord Metrotown (Phase 2) ",
  "Harrison",
  "13245 104 Avenue Condos",
  "1510 W 71st Avenue Condos",
  "Southgate Village",
  "Field + Fort",
  "Pine and Glen",
  "Highpoint",
  "625 W 33 Avenue Condos",
  "Baycrest West",
  "One Park (Phase 3)",
  "Unity",
  "OPUS",
  "Aspen by Zako",
  "The Grove",
  "SAMER",
  "20792 68 Avenue Townhomes",
  "Moments ",
  "20100 Block of 84 Avenue Townhomes",
  "21406 & 21446 80 Avenue Townhomes",
  "18772 74 Avenue Townhomes",
  "Icon at Southgate City",
  "Whitford",
  "RC at CF Richmond Centre",
  "582 - 588 West King Edward Avenue Condos",
  "4125 Hastings Street Condos",
  "Glenbrook Row",
  "Emerald",
  "Park Langara",
  "Oakridge x Westbank",
  "Unison",
  "Jasper",
  "Shyne Townhomes",
  "Uplands",
  "Georgetown Three - Midrise",
  "Adore 49th",
  "Oakridge Park",
  "Gardena",
  "10730 132A Street Townhomes",
  "4180 Lougheed Highway Condos",
  "7642 206 Street Condos",
  "The Passages",
  "3507 Hall Avenue Townhomes",
  "7536 200 Street Condos",
  "Pine and Glen (East Tower)",
  "Salishan - Phase 3",
  "5360 204 Street Condos",
  "PORTAL",
  "4444-4488 Kingsway Condos",
  "877 - 887 W 28th Avenue Townhomes",
  "655 Harrison Avenue Townhomes",
  "1170 East 27th Street Condos",
  "Emerald Ridge ",
  "Linx",
  "6288 Ash Street Townhomes",
  "Queenston",
  "Talistar- Pending Phases",
  "21305 83 Avenue Townhomes",
  "20239 - 20249 54A Avenue Condos",
  "12129 Edge Street Condos",
  "376-406 West 45th Avenue Townhomes",
  "23613 132 Avenue Condos",
  "7858 202A Street Townhomes",
  "Lina at QE Park",
  "Ardor Steveston",
  "6690 King George Boulevard Townhomes",
  "Ashleigh Oakridge",
  "Rydge",
  "Winona",
  "South Yards (Phase 4) ",
  "7354 184 Street Condos",
  "Latitude on Cambie",
  "4310 Marine Drive Townhomes",
  "Sacred Waters Master Plan",
  "South Village Condos at Latimer Heights",
  "1008 West 52 Avenue Townhomes",
  "Tidal Homes - Single-Family Homes",
  "5502 Lougheed Highway Condos",
  "2045-2075 Old Dollarton Road Condos",
  "2800 Barnet Highway Condos",
  "The Falls - Kanaka Springs",
  "Innovation Village",
  "Deltita Gardens",
  "10611 150 Street Condos",
  "80-85 Electronic Avenue Condos",
  "West Wind at Lelem Townhomes",
  "Linden House",
  "Park and Maven (Condos - Sparrow and Osprey)",
  "7240 & 7258 210 Street Townhomes",
  "11902 232nd Street Townhomes",
  "South Yards (Phase 1 - Tower A)",
  "7688 - 7720 Cambie Street Condos",
  "Terrayne",
  "7582 Armstrong Street",
  "2062-2092 East Broadway Condos",
  "View Star",
  "105 Keefer Street Condos",
  "15327 104 Avenue Condos",
  "View Star (Bldg J and A)",
  "Bourna",
  "10068 133 Street Condos",
  "9933 Barnston Drive East Townhomes",
  "6390 Willingdon Avenue Condos",
  "1331 Ambleside Residences",
  "12674 King George Boulevard Condos",
  "9091 9111 9151 Alexandra Road Condos",
  "Cambie Station",
  "Hailey",
  "Kerrisdale",
  "591 West 29th Avenue Townhomes",
  "Pinnacle Living at Capstan Village (Phase 4)",
  "6508 Telford Avenue Condos",
  "12211 228 Street Townhomes",
  "Rove",
  "1150 Barclay Street Condos",
  "Symposia",
  "King and Columbia (Phase 2)",
  "Morrison Walk",
  "515 W 60 Avenue Townhomes",
  "Seymour Village - Lupine Walk (East & West Building)",
  "Victory ",
  "Solid",
  "Willow Heights",
  "Frame",
  "18778 72 Avenue Condos",
  "8 Laurel",
  "19984 76 Avenue Condos",
  "Plaza of Nations",
  "Harlin",
  "Town and Centre - South Tower",
  "20230 72B Avenue Condos",
  "4472 Hastings Street Condos",
  "Voyce",
  "13915 64 Avenue Townhomes",
  "Elements",
  "8709 Cartier Street Townhomes",
  "Chloe",
  "13874 58 Avenue Townhomes",
  "909 - 915 Twelfth Street Condos",
  "6645-6707 Dow Avenue Condos",
  "City of Lougheed",
  "20400 Block of 78 Avenue Townhomes",
  "Prestige - Duplex Homes",
  "10054 Whalley Boulevard Condos",
  "York",
  "23220 Willett Avenue Townhomes",
  "20347 68 Avenue Townhomes",
  "The Beverly at Fraser Heights",
  "12697 80 Avenue Condos",
  "Evelyn - Forest's Edge 3 and 4 - Single-Family Homes",
  "2149-2159 Grant Avenue Condos",
  "Guildford the Greatest",
  "Arden",
  "Templeton",
  "691 West 28th Avenue Townhomes",
  "Kestrel",
  "20659 - 20679 Eastleigh Court Condos",
  "7320 Canada Way Condos",
  "Savoy at Queen Elizabeth Park",
  "10320 & 10350 Slatford Place Townhomes",
  "Gryphon Nova",
  "8380 160 Street Condos",
  "Elwynn Green",
  "Oaklyn",
  "The Met",
  "Camden Square",
  "5811 175 Street Townhomes",
  "King + Crescent (Fir, Willow and Maple)",
  "Lelem Phase 3",
  "15010-15030 108 Avenue Condos",
  "72 Crossroads Condos",
  "Luxio on the Park",
  "Brighouse22",
  "Kora",
  "3510 Main Street and 208 East 19th Avenue Condos",
  "20645 74B Avenue Townhomes",
  "160 W 44 Avenue Townhomes",
  "22057 22083 Lougheed Highway Condos",
  "The Pacific",
  "The Kensington Parkside Residences",
  "21938-21988 Lougheed Highway Condos",
  "Archer | Creekside Living",
  "The Standard",
  "717 Edgar Avenue Townhomes",
  "13760 101A Avenue Condos",
  "24392 104 Avenue Townhomes",
  "James Hill",
  "Park George",
  "550 Cottonwood Avenue Condos",
  "South Yards (Phase 1 - Tower B)",
  "Raven's Park",
  "2518- 2582 West Broadway Condos",
  "Exeter Hall",
  "Just West",
  "Rupert",
  "UBC",
  "3015 - 3059 Woodbine Drive Condos",
  "District Northwest - North Tower",
  "11926 236 Street Townhomes",
  "10366 240 Street Townhomes",
  "The Idyllic",
  "4869 Slocan Street Condos",
  "12716 King George Boulevard Condos",
  "13557 Hilton Road Condos",
  "PURA - Phase Two",
  "3575 Sawmill Court Condos",
  "2296 Cambridge Street Townhomes",
  "Century City Holland Park - Tower 1",
  "Executive on the Park",
  "21668 Lougheed Highway Condos",
  "20320 80 Avenue Townhomes",
  "988 W 32 Avenue Townhomes",
  "The Heights on Austin- West Tower",
  "Naimo East Van",
  "Estella",
  "13433 Gateway Drive Condos",
  "19610 78 Avenue Townhomes",
  "1289 Nicola",
  "20441 68 Avenue Townhomes",
  "Olo",
  "Bromley",
  "Hollybridge at River Green",
  "207 East 4th Avenue Condos",
  "14328 104 Avenue Condos",
  "Latimer Walk",
  "Hudson 8",
  "BridgeCity",
  "10451 128 Street Townhomes",
  "Alpine",
  "2050-2070 Marine Drive Condos",
  "20701 84 Avenue Street Condos",
  "18253 74 Avenue Townhomes",
  "Solhouse 6035",
  "7425 Granville Street Townhomes",
  "17358 58 Avenue Townhomes",
  "11692 Pine Street Condos",
  "Inlet District",
  "18160 72 Avenue Townhomes",
  "Sparrow, Chinatown",
  "Twelve at Norquay Park",
  "VERTEX",
  "Abbey Gardens Townhouses",
  "Italia",
  "Oakmont",
  "Parc Riviera (Future Phases)",
  "15284-15296 Fleetwood Drive Condos",
  "Encore",
  "Southgate Village ",
  "Harper Peak",
  "573 - 605 Madore Avenue Townhomes",
  "10595 137A Street Condos",
  "12463 105 Avenue Townhomes",
  "4411 Hastings Street Condos",
  "9720 Cambie Road Condos",
  "13228 84 Avenue Townhomes",
  "7119-7143 207 Street Townhomes",
  "6333 Cooney Road Condos",
  "10662 King George Boulevard Condos",
  "Liberty Encore",
  "21668 Dewdney Trunk Road Townhomes",
  "Alina",
  "7371 No 4 Road Townhomes",
  "7730-7770 Cambie Street Condos",
  "Oaklyn 49th + Oak",
  "The Grande at Suter Brook Village - West Tower",
  "Clarke Road & Hobbis Way Condos",
  "15377 88 Avenue Condos",
  "10611 River Drive Condos",
  "7291 188 Street Condos",
  "15336 101A Avenue Condos",
  "Monogram",
  "8560 Railway Avenue Townhomes",
  "13453 111A Avenue Townhomes",
  "625 777 Pacific Street Condos",
  "250 Agnes Street Condos",
  "Edgemont Village",
  "20390 72 Avenue Condos",
  "1124 Edinburgh Street Condos",
  "Flavelle Oceanfront",
  "8th and Cumberland Townhomes",
  "ENZO",
  "Cameron",
  "Larner Landing",
  "Culmena",
  "City and Laurel",
  "14455 - 14447 104A Avenue Condos",
  "Marilyn on the Park",
  "5163-5187 Joyce Street Condos",
  "6690 King George Boulevard Condos",
  "14570 106 Avenue Condos",
  "Main+35",
  "SOCO Two",
  "Renfrew",
  "Cambie Gardens Phase 1 (West Tower)",
  "Kadence",
  "Bliss at Latimer",
  "Solo District - Aerius",
  "5900 River Road Condos",
  "Town and Centre - North Tower",
  "11405 236 Street Townhomes",
  "Brookswood Estates",
  "Luxe on Lansdowne",
  "Autograph",
  "20247 Patterson Avenue Townhomes",
  "18757 74 Avenue Townhomes",
  "SOCO (Phase 3)",
  "6869 210 Street Townhomes",
  "North Harbour - Phase 2",
  "20701 84th Avenue Townhomes",
  "12102 237 Street Townhomes",
  "20290 72 Avenue Condos",
  "4587 & 4545 Juneau Street Condos",
  "W63 Mansion",
  "20615 67 Avenue Condos",
  "6851 and 6871 Elmbridge Way Condos",
  "The Cut",
  "Duet",
  "Oval",
  "2751 Shell Road Townhomes",
  "Metrotown",
  "Nest",
  "2431 Mary Hill Road Condos",
  "11598 224 Street Condos",
  "Cadence",
  "21198 Smith Crescent Townhomes",
  "20199 53A Avenue Condos",
  "18702 74 Avenue Townhomes",
  "13531 95 Avenue Condos",
  "Evelyn - Forest's Edge 3 and 4- Condos  & Townhomes",
  "1400 Alberni",
  "Form",
  "Maple Mews",
  "12209-12231 222 Street Condos",
  "13672 Larner Road Condos",
  "Etoile Gold",
  "6920 204 Street Townhomes",
  "Alaska",
  "Burnaby Lake Heights",
  "900 Robinson Townhomes",
  "22295-22299 Dewdney Trunk Road Condos",
  "8146 200 Street Condos",
  "20815 45A Avenue Townhomes",
  "7642 206 Street Townhomes",
  "Civic District",
  "The Hudson",
  "24070 128 Avenue Townhomes",
  "20040 & 20056 40A Avenue Condos",
  "20800 Block 40 Avenue Townhomes",
  "Ridgewood Townhomes",
  "Inspire - Phase 3",
  "8828 Osler Street Condos",
  "Landmark on Robson - East Tower",
  "Walnut Park",
  "21698 Lougheed Highway Condos",
  "20186 and 20212 86 Avenue Condos",
  "Ibis Residences",
  "Fleetwood Village II (Condos Building 6)",
  "7449 200 Street Condos",
  "1943-1999 East Hastings Street Condos",
  "Octave",
  "Oakridge x Clémande",
  "The Trails",
  "Ksana",
  "576 - 590 Lougheed Highway Condos",
  "Gilmore Place Phase 2 (Tower 6)",
  "Anchor",
  "14723 104 Avenue Condos",
  "739 Robinson Street Condos",
  "2601 Lonsdale Avenue Condos",
  "9126 - 9130 176 Street Condos",
  "4339 Cambie Street Condos",
  "544 Austin Avenue Condos",
  "Atmos",
  "197 West 26 Avenue Townhomes",
  "7873 14th Avenue Condos",
  "1515 Rupert",
  "Venda",
  "Oakhaus",
  "Vera",
  "Ethos",
  "Grant",
  "Brentwood West Phase 3",
  "BayCrest Heights",
  "EC3",
  "Terrace House",
  "8611 Cook Road Townhomes",
  "Pierwell",
  "12855 111 Avenue Townhomes",
  "Parkside at Lynn",
  "The Heights on Austin - East Tower",
  "Koen",
  "13379-13389 103 Avenue Condos",
  "1650 Alberni",
  "Porthaven",
  "11920 228 Street Condos",
  "Buchanan West",
  "18495 72 Avenue Townhomes",
  "18514 74 Avenue Townhomes",
  "Woodward Lane Townhomes",
  "Moonlight Sonata",
  "7051 Ash Crescent Condos",
  "4000 Block Steveston Highway Townhomes",
  "13760 Bentley Road Condos",
  "20963 Lougheed Highway Condos",
  "One Central",
  "3846 Carrigan Court Condos",
  "9850 Austin Road Condos",
  "9640 - 9700 Alberta Road Townhomes",
  "20390 72 Avenue Townhomes",
  "7790 & 7850 King George Boulevard Condos",
  "Heather Lands",
  "3633 Victoria Drive Townhomes",
  "Lilibet",
  "Ashton",
  "Wellwood",
  "Bellevue Avenue and 22nd Street Condos",
  "m/6",
  "Partington Creek",
  "11970 220 Street Condos",
  "SOTO on W28 - Condos & Townhomes",
  "2160-2168 Old Dollarton Road Condos",
  "12178 Fletcher Street Townhomes",
  "Provenance",
  "VUE6",
  "5777 Willingdon Avenue Condos",
  "The Charles",
  "20876 76 Avenue Townhomes",
  "Dahlia",
  "5978 Wilson Avenue Condos",
  "The Amazing Brentwood- Tower Six",
  "Garden 11",
  "Zail Cambie",
  "EIGHT in Edgemont Village",
  "5900 2 Road Condos",
  "4700 and 4800 Kingsway Condos",
  "Stowa",
  "102-128 East 8th Avenue Townhomes",
  "Auberry",
  "4902 & 4946 Joyce Street Condos",
  "Vue",
  "Abbey Gardens",
  "Central Park Residences",
  "Element 2 Townhomes",
  "Azure at Southgate City",
  "Oak & W.58",
  "Vesa at Little Mountain",
  "5392-5472 Manson Street Condos",
  "V on E49",
  "Highgate Tower",
  "5000 Kingsway Condos",
  "14488 105A Avenue Condos",
  "Gordon Square",
  "4612 and 4650 Dawson Street Condos",
  "Hartley",
  "Sailshan - Phase 2",
  "8899 Spires",
  "Juno",
  "Koi",
  "7180 No 3 Road Condos",
  "M Residence and Spa",
  "Nora",
  "Westport Village",
  "2279 Kelly Avenue Condos",
  "4611 & 4591 Smith Court Townhomes",
  "Piano - South Tower",
  "18742 72 Avenue Condos",
  "2833 Chippendale Road Townhomes",
  "513-566 Brookmere Avenue Condos",
  "4068 Cavendish Drive Townhomes",
  "Inspire - Phase 2",
  "22108-22164 Lougheed Highway Condos",
  "7330 Sixth Street Condos",
  "Tailor",
  "Timber House",
  "23682 Dewdney Trunk Road Townhomes",
  "Westview Townhomes",
  "Addition",
  "Leah Fort Langley Village Homes",
  "19942 78B Avenue Condos",
  "22597 Dewdney Trunk Road Condos",
  "Gibson House",
  "1369 Coast Meridian Road Townhomes",
  "West Village - Neon",
  "Kensington Gate",
  "7126 182 Street Condos",
  "Fleetwood Village II (Condos Building 7)",
  "427-477 West 49th Avenue Condos",
  "Coast at Fraser Heights",
  "904 Davie Street Condos",
  "Janda Tower",
  "Steelix",
  "11944 92 Avenue Condos",
  "Block Residences",
  "Boden",
  "Holland Row",
  "Whalley Station",
  "22058 119 Avenue Condos",
  "Thesis",
  "Eastin Townhomes",
  "Smith & Farrow",
  "O2 Metrotown",
  "5412 Cambie Street Condos",
  "5515 - 5525 Elizabeth Street Condos",
  "2 Burrard Place",
  "14774 106 Avenue Condos",
  "Core",
  "10825 132A Street Condos",
  "4214 Maywood Street Condos",
  "Ava",
  "947 - 955 Robinson Street Condos",
  "2245 McAllister Avenue Condos",
  "11646 228 Street Townhomes",
  "INNOVA NORTH",
  "104 Plaza",
  "11607 Burnett Street Condos",
  "7201 11th Avenue Condos",
  "22780 Dewdney Trunk Road Condos",
  "Elmwood",
  "13758 Larner Road Condos",
  "Ashlyn",
  "3819 & 3841 Canada Way Condos",
  "Coronation Heights",
  "1026 Brunette Avenue Condos",
  "4620 & 4640 Smith Court Townhomes",
  "12149 80 Avenue Condos",
  "10294 240 Street Townhomes",
  "Prima",
  "Tesoro",
  "1290 Hornby Street Condos",
  "19653 - 19665 Willowbrook Drive Condos",
  "Oasis at Concord Brentwood (Circle)",
  "1608-1616 West Georgia Street Condos",
  "Florence on Fraser",
  "405-485 Marie Place Condos",
  "Bayrock Terrace",
  "The Sky Estates at Highline Metrotown",
  "14 Glenmore Drive",
  "10744 City Parkway Condos",
  "Crest",
  "Zail Willoughby Langley II",
  "Hawthorne",
  "5044 & 5056 Imperial Street Condos",
  "Sydney Avenue Townhomes",
  "707 W 27 Avenue Townhomes",
  "368 - 380 W 62 Avenue Townhomes",
  "Fifteen Fifteen",
  "August",
  "3282-3302 Queens Avenue Townhomes",
  "11894 Laity Street Townhomes",
  "Hayer Town Centre ",
  "10466 140B Street Condos",
  "1038 1042 Alderson Avenue Condos",
  "Oku",
  "Peridot",
  "4876-4938 57 Street Townhomes",
  "15269 104 Avenue Condos",
  "7725 208 Street Townhomes",
  "3710 Victoria Drive Townhomes",
  "22490 121 Avenue Condos",
  "19752 80 Avenue Townhomes",
  "18241 73 Avenue Townhomes",
  "The Row on French",
  "Popolo",
  "7969 7989 Cambie Street Condos",
  "2300 Madison Avenue Condos",
  "4141 Lougheed Highway Condos",
  "9273 173A Street Townhomes",
  "Granville Street & West 52nd Avenue",
  "Parkway (Phase 4)",
  "One Park (Phase 1 and 2)",
  "7544 Bevan Street Townhomes",
  "9500 Garden City Road Townhomes",
  "1160 Inlet Street Condos",
  "The Arbutus",
  "13265 104 Avenue Condos",
  "Saxon Park",
  "817 Edgar Avenue Townhomes",
  "Cypress Village",
  "14876 Fraser Highway Condos",
  "6580 Cooney Road Townhomes",
  "Deer Lake Mews",
  "Gilmore Place Phase 2 (Tower 5)",
  "4711-4787 Cambie Street Condos",
  "Galilea",
  "Pier West (West Tower)",
  "Turner's Dairy",
  "Aston",
  "The Warwick",
  "9884 - 9892 138 Street Condos",
  "Spera",
  "Wildwood",
  "Sitka House",
  "The Trails (Phase 1)",
  "Jericho Lands Development",
  "489 Marie Place Condos",
  "Fellow",
  "7109 18th Avenue Condos",
  "5886 128 Street Townhomes",
  "Ladner Square",
  "13285 Balsam Street Condos",
  "Highpointe",
  "Oak Keys",
  "Townside Village - Townhomes",
  "13301 104 Avenue Condos",
  "GEC Education Mega Centre",
  "802 & 809 Eighth Street Townhomes",
  "Obsidian",
  "6828 128 Street Condos",
  "604-608 Sydney Avenue Townhomes",
  "5760-5786 Blundell Road Townhomes",
  "Oakley",
  "10520 132 Street Condos",
  "23031 Dewdney Trunk Road Townhomes",
  "14040 100A Avenue Condos",
  "13425 107A Avenue Condos",
  "Aegean Garden",
  "5950 144 Street Townhomes",
  "Skye at Holly Park",
  "Florin",
  "3625 Brownlee Avenue Townhomes",
  "Wilford",
  "The Commons",
  "The Stand",
  "Entroterra YVR",
  "Chronicle",
  "2615 Trimble Street Condos",
  "108 Avenue and University Drive Condos",
  "1335 Howe",
  "2027-2033 York Avenue Townhomes",
  "20315 & 20323 78 Avenue Townhomes",
  "9450 172 Street Condos",
  "1818 Alberni",
  "Rindall",
  "Crofton Living",
  "Berkshire Living",
  "10515 138 Street Condos",
  "4001 Knight Street Condos",
  "SkyLiving - By Allure Ventures",
  "Edge",
  "2555 Discovery Street Condos",
  "MODE (River District)",
  "Harriswood",
  "Lelem Phase 2",
  "6898 204 Street Townhomes",
  "Cascadia",
  "1010 W King Edward Avenue Condos",
  "6225 Royal Oak Avenue Condos",
  "1005 Ewen Avenue Townhomes",
  "Paradigm",
  "6660 - 6788 200 Street Condos",
  "3321 200th Street Townhomes",
  "King & Columbia (Phase 1)",
  "4375 Willow Street and 817-837 West 28th Avenue Townhomes",
  "Portico",
  "Sydney",
  "5292 and 5318 Kingsway Condos",
  "The City of Lougheed - Tower THREE",
  "6051 King George Boulevard Townhomes",
  "7236 and 7248 Salisbury Avenue Condos",
  "7620 197 Street Townhomes",
  "7573 and 7595 203B Street Condos",
  "7860 197 Street Townhomes",
  "1923-1959 Purcell Way Condos",
  "7929 7949 Cambie Street Condos",
  "Scott + 77 (Phase 2)",
  "Gilmore Place",
  "Knight 18",
  "502 West 61st Avenue Townhomes",
  "1235 Marine Drive Condos",
  "Pine Creek Estates 2",
  "1900 Sandown Place Townhomes",
  "Carvolth Townhomes",
  "1780 East Broadway Condos",
  "7700 Block of 202A Street Townhomes",
  "3625 Kingsway Condos",
  "325 East 6 Avenue Condos",
  "Lennox",
  "The Grande at Suter Brook Village - East Tower",
  "Mercer Village",
  "6396 King George Boulevard Condos",
  "Bassano by Boffo",
  "Metro21",
  "815 Hornby Street Condos",
  "8257 Oak Street Condos",
  "18756 18778 10 Highway Condos",
  "21728 Lougheed Highway Condos",
  "1781 Prairie Avenue Townhomes",
  "Southaven",
  "TriCity Central",
  "3731-3743 W 4th Avenue Condos",
  "810 Agnes Street Condos",
  "Concord Metrotown - Grand Tower",
  "Towns at Lynn",
  "Brighton North",
  "Parksville 96",
  "8393 200 Street Condos",
  "Bridge & Elliott",
  "Woodward",
  "Briar Block",
  "7680 202A Street Townhomes",
  "W16 by Olson Kundig",
  "Park and Maven (Townhomes)",
  "20220 - 20230 Michaud Court Condos",
  "1294 Granville Street Condos",
  "17399 59 Avenue Townhomes",
  "Avantra",
  "1010 Villas",
  "Victory - Phase 2",
  "9955 Barnston Drive East Condos",
  "4165-4195 Marine Drive Townhomes",
  "Kwasen Village",
  "13960 232 Street Townhomes",
  "5963 176 Street Condos",
  "Skylark",
  "1688 Alberni Street Condos",
  "23360 Westminster Highway Townhomes",
  "Artesia",
  "Centra",
  "Atara",
  "Piero Lissoni x Oakridge",
  "Solstice",
  "Terra Garden",
  "Tisdall Living",
  "Brighton West",
  "17101 80 Avenue Townhomes Townhomes",
  "Insignia",
  "18868 74 Avenue Townhomes",
  "Cedar Creek (Signature Collection)",
  "22323 Callaghan Avenue Condos",
  "15711 32 Avenue Townhomes",
  "6378-6392 Mckay Avenue Condos",
  "1036 Alderson Condos",
  "Mason Living",
  "4400-4440 Smith Crescent Townhomes",
  "2550 Garden Drive",
  "727 North Road Condos",
  "644 Woodland Drive Townhomes",
  "3660 152 Street Townhomes",
  "3282 202 Street Townhomes",
  "Aavand",
  "Westhampton at Hampton Cove",
  "6691 Dow Avenue Condos",
  "625 W 54 Avenue Townhomes",
  "7625 204 Street Condos",
  "Lansdowne Mall - Phase 1",
  "View Star I - Building D",
  "Hamilton Village - Phase 2 East",
  "7130 202B Street Condos",
  "9991 Blundell Road Townhomes",
  "Concord Brentwood - Parkside (Phase 3)",
  "Seylynn Gardens",
  "Grace",
  "Atwood",
  "Como Lake",
  "20370 and 20392 73A Avenue Condos",
  "Barclay x Thurlow",
  "3428 - 3464 Mount Seymour Parkway Townhomes",
  "PARK",
  "Edition",
  "818 W 15 Street Condos",
  "Nova",
  "Haven",
  "Belle Isle North",
  "The Merchant",
  "Headwater",
  "Perla",
  "Elle",
  "20297 Fraser Highway Condos",
  "Butterfly",
  "Lansdowne Mall",
  "7434-7452 202A Street Townhomes",
  "Wren",
  "Dwell",
  "Burnaby Lake Heights Phase 1",
  "Raphael",
  "Lamont",
  "Mirabel",
  "Gabriel",
  "14498 104A Avenue Condos",
  "Nature Haus",
  "12975 106 Avenue Condos",
  "541 Clarke Road Condos",
  "The Core",
  "Forester at Burke Mountain",
  "Riverside View",
  "3568 and 3572 Mount Seymour Parkway Townhomes",
  "10937 132A Street Condos",
  "7041 193 Street Townhomes",
  "Prestige - Townhomes",
  "Bloom",
  "Dwell on Larch",
  "INNOVA",
  "7465 Griffiths Drive Condos",
  "Rowen W47",
  "2037-2061 East Broadway Condos",
  "Laurel32",
  "20145 84 Avenue Townhomes",
  "2102 - 2106 St. Johns Street Condos",
  "Archer at Latimer",
  "28West Cambie Village",
  "5803 Chambers Street Townhomes",
  "4555 Dunbar Street Condos",
  "Alto on Capitol Hill",
  "152 Street & 56th Avenue Townhomes",
  "Reign - North Tower",
  "SIENA The Heights",
  "6113 144 Street Townhomes",
  "Sun Towers II",
  "255 - 285 SW Marine Drive Condos",
  "7843 Cambie Street Condos",
  "14037 103A Avenue Condos",
  "Avenue 33",
  "9288 120 Street Condos",
  "Jericho - Phase 2",
  "7525 Cambie Street Condos",
  "20700 Block of 80 Avenue Condos",
  "24156 104 Avenue Townhomes",
  "10506-10516 140 Street Condos",
  "West Oak",
  "Atmosphere - Phase 1",
  "5404-5414 207 Street Condos",
  "Eastwoods",
  "7455 - 7495 Royal Oak Avenue Townhomes",
  "SOCO - Phase 5",
  "Park Place",
  "1551 W 7th Avenue Condos",
  "Marco Polo",
  "22557 Brown Avenue Condos",
  "6333 Yukon Street Townhomes",
  "Signature",
  "19536 Fraser Highway Condos",
  "Luxora",
  "Southgate Village Gateway G1 + G2",
  "4560 4580 208 Street Townhomes",
  "Harmony",
  "850 Village Drive Condos",
  "King & Columbia",
  "9609 120 Street Condos",
  "18003 Fraser Highway Condos",
  "6051 King George Boulevard Condos",
  "792 - 800 Clarke Road Townhomes",
  "5910-5998 Cambie Street Condos",
  "526 West 37th Avenue Condos",
  "Mount Pleasant City Homes",
  "7763 7767 Edmonds Street Condos",
  "Mayfair West",
  "Myriad",
  "Eila on W49",
  "20411 Fraser Highway Condos",
  "601 Beach Condos",
  "7086 192 Street Townhomes",
  "112 Moray Street Townhomes",
  "20300 Douglas Court Condos",
  "Georgetown Tower- 4-7",
  "3158 Street George Street Condos",
  "Zaina Gardens",
  "Gateway Drive & City Parkway Condos",
  "18493 Fraser Highway Condos",
  "Be W 49th",
  "Heartwood",
  "The Capstan",
  "7354 140 Street Condos",
  "9327 173A Street Townhomes",
  "Central Living",
  "Hamilton Village - Phase 2 West",
  "20719 - 20731 Eastleigh Court Condos",
  "The Hive 2",
  "Minnekhada",
  "Soenhaus",
  "Creekside Terrace",
  "Ironwood",
  "Brentwood Block",
  "Astrid",
  "19824 54 Avenue Townhomes",
  "Evergreen Phase II",
  "City Corners",
  "9075 152 Street Condos",
  "Onyx",
  "Baden Park",
  "Cambie Gardens - Phase 1 (East Tower)",
  "9601 Lougheed Highway Condos",
  "19585 32 Avenue Townhomes",
  "17380 100 Avenue Townhomes",
  "3676 Bainbridge Avenue Condos",
  "9366 172 Street Townhomes",
  "458 East 1st Avenue Townhomes",
  "328 East 14th Avenue Townhomes",
  "GREENHOUSE by Concord Pacific ",
  "5229-5249 Ash Street Condos",
  "North Gate Residences",
  "Millennium Central Lonsdale",
  "Clive at Collingwood",
  "273-279 East 6th Street Townhomes",
  "Georgetown Three - Tower",
  "Umbra at Portwood",
  "3134 200 Street Townhomes",
  "Heath West",
  "11204 - 11214 Charlton Street Townhomes",
  "23500 Gates Avenue Townhomes",
  "Akimbo",
  "Arbour ",
  "16882 Fraser Highway Townhomes",
  "20287 80th Avenue Condos",
  "10472 140 Street Condos",
  "3382 Gaspe Place Townhomes",
  "Terraces at Oak Park",
  "9933 Barnston Drive East Condos",
  "Panorama 30",
  "Oxford Mews",
  "8011 170A Street Townhomes",
  "Jericho Park (Phase 1)",
  "2929 Barnet Highway Condos",
  "SOCO (Phase 4)",
  "10 Street & Quayside Drive Condos",
  "Lotus - The Botanical Collection",
  "13588 98 Avenue Condos",
  "17129 94A Avenue Condos",
  "320 East 2nd Avenue Condos",
  "Wordsworth",
  "7042 and 7058 204 Street Townhomes",
  "22058 119 Avenue Townhomes",
  "Marlow",
  "9211 Blundell Road Townhomes",
  "6450 Nelson Avenue Condos",
  "7343 Buller Avenue Condos",
  "10925 University Drive Condos",
  "18503 Fraser Highway Condos",
  "Sienna",
  "6579 Fraser Street Condos",
  "566 Lougheed Highway Condos",
  "PARKWAY 2 - Intersect",
  "Our Urban Village - Tomo House",
  "Scale",
  "10627 128 Street Townhomes",
  "51 Elliot Street Condos",
  "Citizen",
  "South Yards (Phase 3)",
  "2634 2638 Kingsway Avenue Condos",
  "20256 - 20272 54a Avenue Condos",
  "809 - 811 Carnarvon Street Condos",
  "320 North Nanaimo Street Condos",
  "146-148 West 41st Avenue Townhomes",
  "8037 - 8087 120 Street Condos",
  "Apex - Seylynn Village",
  "First and Royal",
  "18737 72 Avenue Townhomes",
  "11199 84 Avenue Condos",
  "8080 No 3 Road Townhomes",
  "23301-23381 Gilley Road Condos",
  "Kinship",
  "4269 Hastings Street Condos",
  "Sequoia",
  "4360 Smith Court Townhomes",
  "22066 Lougheed Highway Condos",
  "Highgate Homes",
  "Barclay Tower",
  "6280 192 Street Condos",
  "216 East 3 Street Condos",
  "Fenway",
  "Ledgeview II",
  "The Manhattan",
  "The North at Port Haney",
  "629 - 637 Foster Avenue Townhomes",
  "2905 Marine Drive Townhomes",
  "Lodana",
  "Dharma Village",
  "14318 60 Avenue Condos",
  "CURV",
  "14069 103 Avenue Condos",
  "21456 80 Avenue Townhomes",
  "Lansdowne - Tower 2",
  "Nature's Walk - Single-Family Homes",
  "375 East 1st Avenue Condos",
  "Parc Portofino",
  "7033 204 Street Condos",
  "Kitsilano Block",
  "20309, 20339, 20347 68th Avenue & 6842, 6868 202B Street Townhomes",
  "Azure 2",
  "Woodward Lane",
  "Aviva",
  "Zenith Guildford Condominiums",
  "Botanica",
  "Guilden",
  "12208 206 Street Townhomes",
  "Galleria - Picasso",
  "Sierra",
  "Pinnacle Living at Capstan Village (Phase 3)",
  "464 East 8th Avenue Condos",
  "7734 197 Street Townhomes",
  "5471 - 5493 Steveston Highway Townhomes",
  "5848 175 Street Condos",
  "6225 - 6263 Yukon Street Townhomes",
  "Zaryab Gardens",
  "Mary Anne's Place",
  "7883 199 Street Condos",
  "Amson Bloc",
  "Burqville",
  "Hue",
  "10302 143 Street Condos",
  "Emerald Gardens",
  "1290 Wellon Street Townhomes",
  "20326 & 20350 72 Avenue Townhomes",
  "Elijah ",
  "20110 Lougheed Highway Condos",
  "18599 74 Avenue Townhomes",
  "4355 Maywood Street Condos",
  "4200 Dewdney Trunk Road Condos",
  "TriCity Central - Phase 1",
  "5030 - 5064 208 Street Townhomes",
  "VIVID",
  "Legacy on Dunbar",
  "View Star I - Building E",
  "10275 150 Street Condos",
  "526-528 West 37th Avenue Condos",
  "6444 Silver Avenue Condos",
  "7491 No. 1 Road Townhomes",
  "4906-4970 Quebec Street Condos",
  "Sunnyside",
  "Beaumont",
  "The Park Landmark",
  "Riva 4",
  "5650 Beresford Street Condos",
  "Abigail",
  "597, 605, 613, 621 Foster Avenue Townhomes",
  "9148 Glover Road Condos",
  "Westbury",
  "4410, 4438 Juneau Street Condos",
  "141-155 West 3rd Street Condos",
  "2228 Kelly Avenue Condos",
  "Arcadia",
  "Burquitlam Park District",
  "Pure",
  "Shelby Townhomes",
  "Ridgewood",
  "20700 block 78 Avenue Condos",
  "18737 72 Avenue Condos",
  "8911-8991 Patterson Road Condos",
  "7183 184 Street Condos",
  "20120 86 Avenue Condos",
  "The Grant",
  "Precidia",
  "10695 10707 10713 138 Street Condos",
  "13317 - 13335 King George Boulevard Condos",
  "Claridge House",
  "Miller Avenue Townhomes",
  "The Robinsons - Parkside Collection",
  "13464 Bolivar Court Condos",
  "Komo",
  "1450 West Georgia Street Condos",
  "18828 74 Avenue Townhomes",
  "3469 Baycrest Avenue Townhomes",
  "Amaya Living",
  "18638 74 Avenue Townhomes",
  "Aquila",
  "11675 11678 Burnett Street Townhomes",
  "E15 - Just Off Main",
  "10344 King George Boulevard Condos",
  "Ridgeview Estates",
  "22260 and 22288 48 Avenue Condos",
  "Piano - North Tower",
  "Matteo",
  "Carvolth 2",
  "18175 74 Avenue Townhomes",
  "18611 72 Avenue Townhomes",
  "Aegean Stone",
  "2106 West 8th Avenue Townhomes",
  "22582-22610 121 Avenue Condos",
  "756 W 26 Avenue Townhomes",
  "10318 Whalley Boulevard Condos",
  "Briza",
  "22238 124 Avenue Condos",
  "Hendry",
  "331 Riverside Drive Townhomes",
  "4404 Pender Street Condos",
  "Hunter + West",
  "Nature's Walk - Townhomes",
  "Yorkson SW Townhomes",
  "Seasons",
  "Parc 28",
  "Frank - Building 1",
  "21200 Block of 83 Avenue Townhomes",
  "West",
  "La Riviere",
  "Havenwood in Sullivan",
  "Bell Pointe",
  "Douglas Tower Brentwood",
  "14974 92 Avenue Condos",
  "Savannah",
  "485 West 28th Avenue Condos",
  "Brentwood West Phase 1",
  "Versant",
  "20145 84 Avenue Condos",
  "Crest Lane ",
  "SILVA 3",
  "LUCENT",
  "SEQUOIA",
  "KING + CRESCENT",
  "AMAYA LIVING",
  "CENTURY CITY HOLLAND PARK - PARK TOWER 1",
  "DISTRICT NORTHWEST - NORTH TOWER",
  "PURA - PHASE 2",
  "PURA - PHASE 1",
  "THE GRAND ON KING GEORGE",
  "SOUTHAVEN",
  "VIVA",
  "THE BOROUGHS (PHASE 2) - BEXLEY",
  "ELISA AT SUNNYSIDE PARK",
  "HARTLEY",
  "JUNO",
  "WESTWOOD AT TYNEHEAD",
  "KENDRICK",
  "THE MAISONETTES",
  "OAK MEADOWS",
  "WOODWARD",
  "PARK GEORGE",
  "NOVA",
  "GALILEA",
  "PARK & MAVEN",
  "DOUGLAS GREEN LIVING",
  "PARK & MAVEN (CARDINAL & HERON)",
  "72 CROSSROADS",
  "HARMONY",
  "BRIZA",
  "ONE CENTRAL",
  "THE 85",
  "CENTRAL LIVING",
  "THE PIANO",
  "BELL POINTE",
  "THE LOOP",
  "HEADWATER",
  "COAST AT FRASER HEIGHTS",
  "THE BEVERLY",
  "INSIGNIA",
  "PRESTIGE",
  "PARKVILLE 96",
  "ELWYNN GREEN",
  "GUILDFORD THE GREATEST",
  "CENTRA",
  "OYNX & IVORY",
  "PARKWAY (PHASE 2)",
  "PARK & MAVEN (TOWNHOMES)",
  "LEDGEVIEW (PHASE 2)",
  "JASPER",
  "GLENMONT",
  "CREST LANE",
  "THE PARK LANDMARK",
  "FLEETWOOD VILLAGE 2 (TOWNHOMES)",
  "SOUTH VILLAGE",
  "SKYLIVING",
  "FLEETWOOD VILLAGE 2 (CONDOS) BUILDING 4 + 5",
  "FOX & AIKINS",
  "BOURNA",
  "CADENCE",
  "CREEKSIDE TERRACE",
  "PANORAMA WEST COAST LIVING",
  "BAILEY",
  "MONUMENT",
  "THESIS",
  "2550 GARDEN DRIVE",
  "HARLIN",
  "1818 ALBERNI",
  "THE PACIFIC",
  "CURV",
  "OAKRIDGE X WESTBANK",
  "POPOLO",
  "WOODLAND BLOCK",
  "FORMAT",
  "ASH l 28",
  "EARL",
  "YORK",
  "THE PALAZZI AT PIERO LISSONI X OAKRIDGE",
  "GRAFIA",
  "THE GRANT",
  "ITALIA",
  "1335 HOWE",
  "GEMINI MOUNT PLEASANT",
  "OKU",
  "WORDSWORTH",
  "MIRABEL",
  "LAKEWOOD",
  "RUPERT",
  "V ON E49",
  "GRACE",
  "ROWEN W47",
  "TURNER'S DAIRY",
  "THE CONSERVATORY",
  "GARDEN 11",
  "LANDMARK ON ROBSON - EAST TOWER",
  "CLARIDGE HOUSE",
  "ELM41",
  "ACE",
  "OAK KEYS",
  "DUNBAR AT 39TH",
  "CAMBIE GARDENS - PHASE 1 (EAST TOWER)",
  "HAMPTON COURT",
  "CAMBIE GARDENS - PHASE 1 (WEST TOWER)",
  "TESORO",
  "KNIGHT 18",
  "LINA AT QE PARK",
  "CHLOE",
  "AVALON 3",
  "THE CHARLES",
  "SPARROW, CHINATOWN",
  "REVIVE (WELLSIDE COLLECTION)",
  "WEST OAK",
  "FRAME",
  "FORM",
  "LAUREL32",
  "BUTTERFLY",
  "CLARENDON HEIGHTS",
  "LEGACY ON DUNBAR",
  "PURE",
  "THE HILLCREST",
  "JUST WEST",
  "FIFTEEN FIFTEEN",
  "ELIOT AT NORQUAY",
  "PINNACLE ON THE PARK",
  "PARADIGM",
  "TEMPLETON",
  "5656 BALACLAVA",
  "W63 MANSION",
  "MODE (RIVER DISTRICT)",
  "KITSILANO BLOCK",
  "SAVOY AT QUEEN ELIZABETH PARK",
  "TWELVE AT NORQUAY PARK",
  "NORA",
  "DUET",
  "THE CHARLOTTE",
  "OAKHAUS",
  "1289 NICOLA",
  "RIAA IN THE PARK",
  "8X ON THE PARK",
  "AUTOGRAPH",
  "GRYPHON NOVA",
  "SOTO ON W28",
  "AVENUE 33",
  "PENTHOUSE GROUP",
  "KING & COLUMBIA (PHASE 2)",
  "THE FIFTEEN",
  "TOMO HOUSE",
  "ADORE l 49TH",
  "EILA ON W49",
  "BLOCK RESIDENCES",
  "BODEN",
  "ROWE",
  "SAVANNAH",
  "PARK LANGARA",
  "THE ARBUTUS",
  "OAKRIDGE X CLEMANDE",
  "PIERO LISSONI & OAKRIDGE",
  "OAKRIDGE",
  "2 BURRARD",
  "RAE GARDEN",
  "TERRACES AT OAK PARK",
  "2027-2033 YORK AVENUE TOWNHOMES",
  "JOYCE",
  "LILIBET",
  "SEASONS",
  "BEAU",
  "GRYPHON HOUSE",
  "CENTURY HOUSE",
  "HUDSON 8",
  "582 - 588 WEST KING EDWARD AVENUE CONDOS",
  "DAHLIA",
  "ASH & EDWARDS",
  "TISDALL LIVING",
  "E15 - JUST OFF MAIN",
  "PARK",
  "MARCO POLO",
  "EC3",
  "FOREST EDGE",
  "1640-1650 ALBERNI STREET CONDOS",
  "W16",
  "1400 ALBERNI",
  "ARDEA",
  "VERDURE",
  "CARVEN",
  "BE W 49TH",
  "ASHLEIGH OAKRIDGE",
  "375 EAST 1ST AVENUE",
  "LANDMARK ON ROBSON - WEST TOWER",
  "OAK & W.58",
  "THE ROW ON FRENCH",
  "MAIN+35",
  "3510 MAIN STREET & 208 19TH AVENUE",
  "AUBERRY",
  "197 W 26 AVENUE",
  "WELLSIDE COLLECTION",
  "EMERALD",
  "ELLE",
  "ROVE",
  "APEX - SEYLYNN VILLAGE",
  "YEW AT BADEN PARK",
  "1515 RUPERT",
  "INNOVA",
  "INNOVA NORTH",
  "VERTEX",
  "PARK WEST AT LIONS GATE",
  "TERZA",
  "TOWNS AT LYNN",
  "ASHTON",
  "MILLENNIUM CENTRAL LONSDALE",
  "EASTWOODS",
  "PARKSIDE AT LYNN",
  "NORTH HARBOUR - PHASE 2",
  "3015 - 3059 WOODBINE DRIVE AT EDGEMONT VILLAGE",
  "LENNOX",
  "THE SENTINEL",
  "1331 AMBLESIDE RESIDENCES",
  "EXECUTIVE ON THE PARK",
  "ASTRID",
  "EVELYN - FOREST'S EDGE 3 & 4",
  "HORSESHOE BAY",
  "HAWKSLEY",
  "WESTON PLACE",
  "PIERWALL",
  "THE UPLANDS",
  "TAILOR",
  "OASIS AT CONCORD BRENTWOOD - EAST TOWER",
  "GREENHOUSE BY CONCORD PACIFIC",
  "THE SKY ESTATES OF HIGHLINE METROTOWN",
  "ICON AT SOUTHGATE CITY",
  "THE AMAZING BRENTWOOD - TOWER 6",
  "REIGN",
  "THE CITY OF LOUGHEED - TOWER THREE",
  "NIDO",
  "ETOILE GOLD",
  "THIND BRENTWOOD - LUMINA (ECLIPSE)",
  "RIVIERA",
  "ALASKA",
  "SLATE",
  "SOLO DISTRICT - AERIUS",
  "SOUTH YARDS (PHASE 1 - TOWER B)",
  "SOCO TWO",
  "SOLHOUSE 6035",
  "SUSSEX",
  "ARBOUR",
  "CITIZEN",
  "PERLA",
  "CEDAR CREEK (SIGNATURE COLLECTION)",
  "ARTESIA",
  "BORDEAUX",
  "OSLO",
  "HIGHGATE HOMES",
  "TELFORD",
  "SIENA THE HEIGHTS",
  "CONCORD METROTOWN - GRAND TOWER",
  "METRO21",
  "SUN TOWERS II",
  "BRENTWOOD BLOCK",
  "AZURE 2",
  "O2",
  "IMPRESSIONS",
  "MOONLIGHT SONATA",
  "THE STANDARD",
  "NUVO",
  "GILMORE PLACE",
  "AKIMBO",
  "SOUTH YARDS (PHASE 1 - TOWER A)",
  "BASSANO",
  "BYRNEPARK - TOWNHOMES",
  "DEER LAKE MEWS",
  "ALTO ON CAPITOL HILL",
  "ATMOSPHERE - PHASE 1",
  "VIEW STAR (BLDG J&A)",
  "VIEW STAR I - BUILDING E",
  "ONWARD",
  "ARDOR STEVENSTON",
  "JADE COLLECTION AT 2 RIVER GREEN",
  "TALISTAR",
  "PRIMA",
  "AVIARY LIVING",
  "LUXE ON LANSDOWNE",
  "CASCADE CITY",
  "ONE PARK (PHASE 1 & 2)",
  "RC AT CF RICHMOND CENTRE",
  "GLADWYN",
  "TORINO - PINNACLE LIVING AT CAPSTAN VILLAGE",
  "OVAL",
  "ROSE GARDEN",
  "PARC THOMPSON",
  "PARC PORTOFINO",
  "HOLLYBRIDGE AT RIVER GREEN - TOWER F",
  "VENDA",
  "GALLERIA - PICASSO COLLECTION",
  "PARK RESIDENCES II",
  "RIVER GREEN (MASTER PLANNED COMMUNITY)",
  "BRIGHOUSE22",
  "VIEW STAR I - BUILDING D",
  "RIVA",
  "ONE PARK (PHASE 3)",
  "HAMILTON VILLAGE - PHASE 2 EAST",
  "HAMILTON VILLAGE - PHASE 2 WEST",
  "9720 CAMBIE ROAD CONDOS",
  "GRANVILLE GARDENS II",
  "NORTH GATE RESIDENCES",
  "FRASER MILLS",
  "GARDENA",
  "THE GROVE",
  "LODANA",
  "BURQUITLAM PARK DISTRICT",
  "BOTANICA",
  "SAMER",
  "BAYCREST WEST",
  "SOPHORA",
  "SMITH & FARROW",
  "DWELL",
  "DEBUT",
  "TERRAYNE",
  "SOENHAUS",
  "THE HEIGHTS ON AUSTIN - WEST TOWER",
  "THE OAK PHASE 3 (WILLOW)",
  "QUEENSTON",
  "AAVAND",
  "RILEY PARK SOUTH",
  "MYRIAD",
  "PINE & GLEN (EAST TOWER)",
  "BASTILLE",
  "HIGHPOINT",
  "AMBER GATE",
  "THE ROBINSONS PARKSIDE COLLECTION",
  "FORESTER AT BURKER MOUNTAIN",
  "SYDNEY",
  "LINC",
  "LAVAL WEST",
  "WEST",
  "PRECIDIA",
  "ELMWOOD",
  "BURQVILLE",
  "KOMO",
  "ALINA",
  "FLORIN",
  "SKYLARK",
  "ASPEN",
  "SALISHAN - PHASE 1",
  "VUE",
  "BAYCREST HEIGHTS",
  "KADENCE",
  "BAND",
  "ARCADIA",
  "HARRISON",
  "RILEY PARK NORTH",
  "HORIZON 21",
  "PINE & GLEN",
  "ELEMENTS",
  "MAGNOLIA",
  "MARLOW",
  "HAWTHORNE",
  "ESTELLA",
  "TIMBER HOUSE",
  "FIRST & ROYAL",
  "SAPPERTON",
  "OVATION",
  "PIER WEST (WEST TOWER)",
  "PIER WEST (EAST TOWER)",
  "CASCADIA",
  "NEWBURY",
  "WESTBROOKE WILLOUGHBY",
  "HAYER TOWN CENTRE",
  "CROFTON LIVING",
  "UNISON",
  "WILLOW",
  "UNION",
  "THE COMMONS",
  "KINSHIP",
  "OAKLEY",
  "THE HIVE 2",
  "WILLOW HEIGHTS",
  "RENFREW",
  "LEAH",
  "WALNUT PARK - BUILDING A",
  "EMERGE",
  "ASTON",
  "KENSINGTON GATE",
  "AVIVA",
  "SOUTH VILLAGE CONDOS AT LATIMER HEIGHTS",
  "THE WILLOUGHBY",
  "WILLOW LIVING",
  "ALDERGROVE TOWN CENTRE",
  "AZURE GROVE",
  "LANDMARK X EVERGREEN TOWNHOMES PHASE II",
  "GORDON SQUARE",
  "SOUTHLANDS",
  "WALKER HOUSE",
  "LINEAGE",
  "GROVEMONT",
  "JEM",
  "SAGE",
  "LEDGEVIEW ROWHOMES",
  "GOLF POINTE ESTATES",
  "VICARRO",
  "LAUREL ON MILL LAKE",
  "RAIL DISTRICT - BRICKYARD & RAIL PLAZA",
  "JACOB",
  "HIGHSTREET VILLAGE",
  "YORK RESIDENCES",
  "VISTA GREEN",
  "SANDSTONE AT WEBSTER CROSSING",
  "EVERWOOD",
  "CITY AND LAUREL",
  "INSPIRE - PHASE 2",
  "HILLSIDE HOMES",
  "PINE CREEK ESTATES 2",
  "RYDGE",
  "WINDFALL",
  "THE FALLS - KANAKA SPRINGS",
  "PROVENANCE",
  "FAIRVIEW GARDENS",
  "EDGE ON EDGE 3",
  "KENNEDY",
  "LA RIVIERE",
  "THE NORTH AT PORT HANEY",
  "THE FALLS",
  "HIGHPOINTE",
  "INSPIRE - PHASE 3",
  "THE LANDMARK AT FOSTER MARTIN",
  "ALTUS",
  "MONACO",
  "SOLEIL",
  "CHERRYVILLE",
  "SILVER & CEDAR",
  "WESTMINSTER PLATEAU",
  "32968-32980 2ND AVENUE CONDOS",
  "7160 OLIVER STREET CONDOS",
  "32860 ROSETTA AVENUE TOWNHOMES",
  "7816 DUNSMUIR STREET CONDOS",
  "9086 EMIRY STREET TOWNHOMES",
  "32352 CHERRY AVENUE TOWNHOMES",
  "UPTOWN CEDAR",
  "SKYVIEW",
  "CEDAR VALLEY ROWHOMES PHASE 2",
  "PARK PLACE TOWNHOMES",
  "THE RIDGE UNIVERSITY HEIGHTS",
  "RADLEY",
  "CANVAS",
  "BRIDGECITY",
  "CHELSEA AT THE BOROUGHS (PHASE 3)",
  "CRESTVIEW",
  "FELLOW",
  "VIVERE",
  "9330 178 STREET TOWNHOMES",
  "10275 150 STREET CONDOS",
  "EASTIN TOWNHOMES",
  "LEAH FORT LANGLEY VILLAGE HOMES",
  "JERICHO PARK - PHASE 1",
  "CRIMSON OAK",
  "ABBEY HEIGHTS",
  "PARKSVILLE 96",
  "UNITY",
  "HUE",
  "NAIMO EAST VAN",
  "WEST COAST ESTATES",
  "MERCER VILLAGE",
  "KING AND COLUMBIA (PHASE 2)",
  "GUILDEN",
  "MORRISON WALK",
  "BOARDWALK - SALT AND MEADOWS 3",
  "THE TRAILS",
  "UMBRA AT PORTWOOD",
  "PORTICO",
  "STOWA",
  "OAKLYN",
  "VOYCE",
  "W68",
  "THE CUT",
  "28WEST CAMBIE VILLAGE",
  "OUR URBAN VILLAGE TOMO HOUSE ON MAIN",
  "PIERO LISSONI X OAKRIDGE",
  "CITY CORNERS",
  "ZENITH",
  "HALO",
  "PIANO - SOUTH TOWER",
  "SLATE AND ASH",
  "DWELL ON LARCH",
  "WILLOW WALK",
  "KINSLEY",
  "AVA",
  "CLIVE AT COLLINGWOOD",
  "AUGUST",
  "VICTORY",
  "MASON LIVING",
  "RIVERSIDE VIEW",
  "16172 24TH AVENUE TOWNHOMES",
  "NEST",
  "HAVENWOOD AT SULLIVAN",
  "13111 KING GEORGE BOULEVARD TOWNHOMES",
  "17380 100 AVENUE TOWNHOMES",
  "6131 KING GEORGE BOULEVARD CONDOS",
  "16172 24TH AVENUE CONDOS",
  "OAKLYN 49TH + OAK, VANCOUVER BC",
  "OUR URBAN VILLAGE - TOMO HOUSE",
  "5515 - 5525 ELIZABETH STREET CONDOS",
  "1650 ALBERNI",
  "2030 BARCLAY",
  "ASHLYN",
  "7051 ASH CRESCENT CONDOS",
  "4683 ARBUTUS STREET TOWNHOMES",
  "WINONA",
  "5229-5249 ASH STREET CONDOS",
  "1700 ALBERNI",
  "WREN",
  "PARK PLACE",
  "KERRISDALE",
  "ZAIL CAMBIE",
  "5659 DUNBAR STREET CONDOS",
  "1450 WEST GEORGIA STREET CONDOS",
  "5019 - 5039 PAYNE STREET CONDOS",
  "MOUNT PLEASANT CITY HOMES",
  "207 EAST 4TH AVENUE CONDOS",
  "OBSIDIAN",
  "1608-1616 WEST GEORGIA STREET CONDOS",
  "FRÄNK",
  "20800 BLOCK 40 AVENUE TOWNHOMES",
  "HENDRY",
  "1551 W 7TH AVENUE CONDOS",
  "THE QE COLLECTION",
  "LITTLE MOUNTAIN BY HOLBORN",
  "LINDEN HOUSE",
  "THE POINT",
  "HERITAGE BY FORMWERKS",
  "CAMBIE GARDENS PHASE 1 (WEST TOWER)",
  "CAMBIE GARDENS",
  "OAKMONT",
  "485 COMMERCIAL DRIVE TOWNHOMES",
  "BLOOM",
  "WHITFORD",
  "2037-2061 EAST BROADWAY CONDOS",
  "904 DAVIE STREET CONDOS",
  "3575 SAWMILL COURT CONDOS",
  "2062-2092 EAST BROADWAY CONDOS",
  "OAKRIDGE PARK",
  "4902 & 4946 JOYCE STREET CONDOS",
  "4555 DUNBAR STREET CONDOS",
  "255 - 285 SW MARINE DRIVE CONDOS",
  "187 WEST KING EDWARD AVENUE TOWNHOMES",
  "6579 FRASER STREET CONDOS",
  "8257 OAK STREET CONDOS",
  "EDITION",
  "OAKRIDGE X CLÉMANDE",
  "OCTAVE",
  "591 WEST 29TH AVENUE TOWNHOMES",
  "QUANTUM PARK",
  "375 EAST 1ST AVENUE CONDOS",
  "JERICHO LANDS DEVELOPMENT",
  "LINX",
  "5910-5998 CAMBIE STREET CONDOS",
  "1780 EAST BROADWAY CONDOS",
  "4711-4787 CAMBIE STREET CONDOS",
  "2 BURRARD PLACE",
  "385 LAKEWOOD DRIVE CONDOS",
  "BARCLAY X THURLOW",
  "5392-5472 MANSON STREET CONDOS",
  "8 LAUREL",
  "6908-6968 ASH STREET TOWNHOMES",
  "2106 WEST 8TH AVENUE TOWNHOMES",
  "CAMBIE STATION",
  "458 EAST 1ST AVENUE TOWNHOMES",
  "BARCLAY TOWER",
  "160 W 44 AVENUE TOWNHOMES",
  "1010 W KING EDWARD AVENUE CONDOS",
  "5198 JAMES STREET CONDOS",
  "HOLBORN LITTLE MOUNTAIN",
  "ORTUS",
  "GIBSON HOUSE",
  "HARLOWE HOUSE",
  "LOTUS - THE BOTANICAL COLLECTION",
  "601 BEACH CONDOS",
  "THE CREEK",
  "BROMLEY",
  "5163-5187 JOYCE STREET CONDOS",
  "2615 TRIMBLE STREET CONDOS",
  "644 WOODLAND DRIVE TOWNHOMES",
  "6288 ASH STREET TOWNHOMES",
  "4375 WILLOW STREET AND 817-837 WEST 28TH AVENUE TOWNHOMES",
  "4261 CAMBIE STREET CONDOS",
  "3510 MAIN STREET AND 208 EAST 19TH AVENUE CONDOS",
  "4869 SLOCAN STREET CONDOS",
  "273-279 EAST 6TH STREET TOWNHOMES",
  "4339 CAMBIE STREET CONDOS",
  "1290 HORNBY STREET CONDOS",
  "MAYFAIR WEST",
  "3731-3743 W 4TH AVENUE CONDOS",
  "1010 VILLAS",
  "320 NORTH NANAIMO STREET CONDOS",
  "197 W 26 AVENUE TOWNHOMES",
  "376-406 WEST 45TH AVENUE TOWNHOMES",
  "1008 WEST 52 AVENUE TOWNHOMES",
  "1419 EAST 27TH AVENUE",
  "7425 GRANVILLE STREET TOWNHOMES",
  "526-528 WEST 37TH AVENUE CONDOS",
  "515 W 60 AVENUE TOWNHOMES",
  "8709 CARTIER STREET TOWNHOMES",
  "1024-1030 EAST 20TH AVENUE TOWNHOMES",
  "2518-2582 WEST BROADWAY CONDOS",
  "1150 BARCLAY STREET CONDOS",
  "5235-5275 KERSLAND DRIVE",
  "7969 7989 CAMBIE STREET CONDOS",
  "7843 CAMBIE STREET CONDOS",
  "5469-5507 WILLOW STREET TOWNHOMES",
  "502 WEST 61ST AVENUE TOWNHOMES",
  "WEST 49TH AVENUE CONDOS",
  "6333 YUKON STREET TOWNHOMES",
  "4906-4970 QUEBEC STREET CONDOS",
  "3158 STREET GEORGE STREET CONDOS",
  "PLAZA OF NATIONS",
  "485 WEST 28TH AVENUE CONDOS",
  "8815-8827 SELKIRK STREET CONDOS",
  "5803 CHAMBERS STREET TOWNHOMES",
  "691 WEST 28TH AVENUE TOWNHOMES",
  "146-148 WEST 41ST AVENUE TOWNHOMES",
  "HEATHER LANDS",
  "815 HORNBY STREET CONDOS",
  "328 EAST 14TH AVENUE TOWNHOMES",
  "6225 - 6263 YUKON STREET TOWNHOMES",
  "157-163 W KING EDWARD AVENUE TOWNHOMES",
  "988 W 32 AVENUE TOWNHOMES",
  "245 EAST 11 AVENUE CONDOS",
  "707 W 27 AVENUE TOWNHOMES",
  "1690 KINGSWAY CONDOS",
  "GRANVILLE STREET & WEST 52ND AVENUE",
  "1510 W 71ST AVENUE CONDOS",
  "526 WEST 37TH AVENUE CONDOS",
  "325 EAST 6 AVENUE CONDOS",
  "6212 6218 ASH STREET TOWNHOMES",
  "105 KEEFER STREET CONDOS",
  "1943-1999 EAST HASTINGS STREET CONDOS",
  "5412 CAMBIE STREET CONDOS",
  "8828 OSLER STREET CONDOS",
  "7929 7949 CAMBIE STREET CONDOS",
  "TERRACE HOUSE",
  "7730-7770 CAMBIE STREET CONDOS",
  "1294 GRANVILLE STREET CONDOS",
  "877 - 887 W 28TH AVENUE TOWNHOMES",
  "625 777 PACIFIC STREET CONDOS",
  "MONOGRAM",
  "3282-3302 QUEENS AVENUE TOWNHOMES",
  "756 W 26 AVENUE TOWNHOMES",
  "463 VICTORIA DRIVE TOWNHOUSES",
  "368 - 380 W 62 AVENUE TOWNHOMES",
  "2296 CAMBRIDGE STREET TOWNHOMES",
  "9884 - 9892 138 STREET CONDOS",
  "10825 132A STREET CONDOS",
  "13760 101A AVENUE CONDOS",
  "GEORGETOWN TOWER- 4-7",
  "10068 133 STREET CONDOS",
  "15336 101A AVENUE CONDOS",
  "16621 19 AVENUE TOWNHOMES",
  "17928 92ND AVENUE TOWNHOMES",
  "2635 158 STREET TOWNHOMES",
  "12149 80 AVENUE CONDOS",
  "DOUGLAS GREEN LIVING - TOWNHOMES",
  "SEMIAHMOO TOWN CENTRE",
  "SHYNE TOWNHOMES",
  "15473 16A AVENUE CONDOS",
  "16611 20 AVENUE TOWNHOMES",
  "13245 104 AVENUE CONDOS",
  "EDGE",
  "ABBEY GARDENS",
  "LEDGEVIEW II",
  "10515 138 STREET CONDOS",
  "19610 78 AVENUE TOWNHOMES",
  "14876 FRASER HIGHWAY CONDOS",
  "DOUGLAS GREEN LIVING (CONDOS)",
  "104 PLAZA",
  "SIGNATURE",
  "10318 WHALLEY BOULEVARD CONDOS",
  "13672 LARNER ROAD CONDOS",
  "14040 100A AVENUE CONDOS",
  "1893 KING GEORGE BOULEVARD TOWNHOMES",
  "18828 74 AVENUE TOWNHOMES",
  "FLEETWOOD VILLAGE II (CONDOS BUILDING 6)",
  "VICTORY - PHASE 2",
  "17101 80 AVENUE TOWNHOMES",
  "13228 84 AVENUE TOWNHOMES",
  "17357 18 AVENUE TOWNHOMES",
  "9955 BARNSTON DRIVE EAST CONDOS",
  "10730 132A STREET TOWNHOMES",
  "13425 107A AVENUE CONDOS",
  "18757 74 AVENUE TOWNHOMES",
  "SILVA 2",
  "17399 59 AVENUE TOWNHOMES",
  "FOX AND AIKINS NORTH",
  "PURA (PHASE 1)",
  "19335 70 AVENUE TOWNHOMES",
  "KING + CRESCENT (CEDAR)",
  "18175 74 AVENUE TOWNHOMES",
  "10054 WHALLEY BOULEVARD CONDOS",
  "13588 98 AVENUE CONDOS",
  "13265 104 AVENUE CONDOS",
  "KING + CRESCENT (FIR, WILLOW AND MAPLE)",
  "7291 188 STREET CONDOS",
  "10466 140B STREET CONDOS",
  "15327 104 AVENUE CONDOS",
  "LIBERTY ENCORE",
  "7086 192 STREET TOWNHOMES",
  "1915 165A STREET TOWNHOMES",
  "EDGEWOOD HEIGHTS",
  "13534 KING GEORGE BOULEVARD CONDOS",
  "FOX AND AIKINS SOUTH",
  "10344 KING GEORGE BOULEVARD CONDOS",
  "10227 KING GEORGE BOULEVARD CONDOS",
  "RAVEN'S PARK",
  "14009 100 AVENUE CONDOS",
  "WOODWARD LANE",
  "9952 149 STREET CONDOS",
  "16620 20 AVENUE TOWNHOMES",
  "AVANTRA",
  "SOUTHEND VILLAGE",
  "16485 18 AVENUE CONDOS",
  "13379-13389 103 AVENUE CONDOS",
  "PARK AND MAVEN (CONDOS - SPARROW AND OSPREY)",
  "ABBEY GARDENS TOWNHOUSES",
  "14723 104 AVENUE CONDOS",
  "GEORGETOWN THREE - TOWER",
  "13758 LARNER ROAD CONDOS",
  "14174 64 AVENUE TOWNHOMES",
  "2301 152 STREET TOWNHOMES",
  "9450 172 STREET CONDOS",
  "PARKWAY 2 - INTERSECT",
  "18241 73 AVENUE TOWNHOMES",
  "GATEWAY TO ALLURING BC",
  "17358 58 AVENUE TOWNHOMES",
  "ABIGAIL",
  "STEELIX",
  "THE BEVERLY AT FRASER HEIGHTS",
  "PARK AND MAVEN (TOWNHOMES)",
  "9327 173A STREET TOWNHOMES",
  "WESTWOOD",
  "13688 GROSVENOR ROAD CONDOS",
  "187 176 STREET CONDOS",
  "THE RIDGE AT GRANDVIEW TRAILS",
  "6631 152 STREET TOWNHOMES",
  "ROCKFORD",
  "WHALLEY STATION",
  "MONTEREY",
  "17174 18 AVENUE TOWNHOMES",
  "FINESTRA",
  "72 CROSSROADS CONDOS",
  "ONYX AND IVORY",
  "1935 165A STREET TOWNHOMES",
  "13915 64 AVENUE TOWNHOMES",
  "14069 103 AVENUE CONDOS",
  "7354 184 STREET CONDOS",
  "1755 176 STREET TOWNHOMES",
  "6051 KING GEORGE BOULEVARD CONDOS",
  "13511 102 AVENUE CONDOS",
  "FLEETWOOD VILLAGE II (CONDOS BUILDING 7)",
  "CIVIC DISTRICT",
  "16709 TO 16739 23RD AVENUE TOWNHOMES",
  "14488 105A AVENUE CONDOS",
  "2484 & 2494 EMERSON STREET CONDOS",
  "13453 111A AVENUE TOWNHOMES",
  "10302 143 STREET CONDOS",
  "15316 88 AVENUE CONDOS",
  "WOODWARD LANE TOWNHOMES",
  "FLAMINGO BLOCK",
  "CORE",
  "10744 CITY PARKWAY CONDOS",
  "18503 FRASER HIGHWAY CONDOS",
  "9650 182A STREET TOWNHOMES",
  "17064 20 AVENUE TOWNHOMES",
  "20700 BLOCK 78 AVENUE CONDOS",
  "6690 KING GEORGE BOULEVARD CONDOS",
  "FLEETWOOD ORION",
  "13557 HILTON ROAD CONDOS",
  "THE LOFT HOUSES AT SOUTHEND VILLAGE",
  "13352 105A AVENUE CONDOS",
  "13464 BOLIVAR COURT CONDOS",
  "9273 173A STREET TOWNHOMES",
  "18493 FRASER HIGHWAY CONDOS",
  "10619 138 STREET TOWNHOMES",
  "10472 140 STREET CONDOS",
  "12697 80 AVENUE CONDOS",
  "9933 BARNSTON DRIVE EAST CONDOS",
  "18737 72 AVENUE TOWNHOMES",
  "14037 103A AVENUE CONDOS",
  "14318 60 AVENUE CONDOS",
  "16882 FRASER HIGHWAY TOWNHOMES",
  "10451 128 STREET TOWNHOMES",
  "9933 BARNSTON DRIVE EAST TOWNHOMES",
  "12463 105 AVENUE TOWNHOMES",
  "1670 154 STREET CONDOS",
  "10595 137A STREET CONDOS",
  "10627 128 STREET TOWNHOMES",
  "18638 74 AVENUE TOWNHOMES",
  "15142 FRASER HIGHWAY CONDOS",
  "9288 120 STREET CONDOS",
  "THE PASSAGES",
  "6280 192 STREET CONDOS",
  "BOURNA ON 105A",
  "18778 72 AVENUE CONDOS",
  "18514 74 AVENUE TOWNHOMES",
  "7790 & 7850 KING GEORGE BOULEVARD CONDOS",
  "GATEWAY DRIVE & CITY PARKWAY CONDOS",
  "19585 32 AVENUE TOWNHOMES",
  "17129 94A AVENUE CONDOS",
  "10662 KING GEORGE BOULEVARD CONDOS",
  "GEC EDUCATION MEGA CENTRE",
  "13833 62 AVENUE TOWNHOMES",
  "12716 KING GEORGE BOULEVARD CONDOS",
  "155, 161, & 193 172 STREET CONDOS",
  "5886 128 STREET TOWNHOMES",
  "245 172 STREET TOWNHOMES",
  "13874 58 AVENUE TOWNHOMES",
  "14774 106 AVENUE CONDOS",
  "PIANO - NORTH TOWER",
  "6113 144 STREET TOWNHOMES",
  "14498 104A AVENUE CONDOS",
  "THE PASSAGES - PHASE 1",
  "19057 FRASER HIGHWAY CONDOS",
  "13433 GATEWAY DRIVE CONDOS",
  "19536 FRASER HIGHWAY CONDOS",
  "5848 175 STREET CONDOS",
  "BERKSHIRE LIVING",
  "7041 193 STREET TOWNHOMES",
  "18702 74 AVENUE TOWNHOMES",
  "15283 - 16651 20 AVENUE CONDOS",
  "12674 KING GEORGE BOULEVARD CONDOS",
  "6690 KING GEORGE BOULEVARD TOWNHOMES",
  "6828 128 STREET CONDOS",
  "152 STREET & 56TH AVENUE TOWNHOMES",
  "15377 88 AVENUE CONDOS",
  "KINGSVILLE",
  "16539 24 AVENUE CONDOS",
  "2249 KING GEORGE BOULEVARD CONDOS",
  "155, 161, & 193 172 STREET TOWNHOMES",
  "17046 20 AVENUE TOWNHOMES",
  "14974 92 AVENUE CONDOS",
  "16468 20 AVENUE TOWNHOMES",
  "WEAVE (CONDOS)",
  "16655 8 AVENUE TOWNHOMES",
  "8380 160 STREET CONDOS",
  "18772 74 AVENUE TOWNHOMES",
  "SPERA",
  "18160 72 AVENUE TOWNHOMES",
  "DHARMA VILLAGE",
  "18599 74 AVENUE TOWNHOMES",
  "18253 74 AVENUE TOWNHOMES",
  "15010 108 AVENUE CONDOS",
  "16765 25 AVENUE TOWNHOMES",
  "1891 165A STREET TOWNHOMES",
  "INNOVATION VILLAGE",
  "5950 144 STREET TOWNHOMES",
  "18611 72 AVENUE TOWNHOMES",
  "10925 UNIVERSITY DRIVE CONDOS",
  "AEGEAN GARDEN",
  "7354 140 STREET CONDOS",
  "5811 175 STREET TOWNHOMES",
  "14570 106 AVENUE CONDOS",
  "NATURE HAUS",
  "2459 156 STREET CONDOS",
  "9366 172 STREET TOWNHOMES",
  "10937 132A STREET CONDOS",
  "2575 152 STREET CONDOS",
  "15624 24 AVENUE TOWNHOMES",
  "WEAVE (TOWNHOMES)",
  "JANDA TOWER",
  "18737 72 AVENUE CONDOS",
  "12855 111 AVENUE TOWNHOMES",
  "18756 18778 10 HIGHWAY CONDOS",
  "TERRA GARDEN",
  "1734 168 STREET TOWNHOMES",
  "13317 - 13335 KING GEORGE BOULEVARD CONDOS",
  "9609 120 STREET CONDOS",
  "10520 132 STREET CONDOS",
  "17029 16 AVENUE TOWNHOMES",
  "WEST VILLAGE - NEON",
  "16370 20 AVENUE TOWNHOMES",
  "PINNACLE",
  "16273 20 AVENUE TOWNHOMES",
  "15269 104 AVENUE CONDOS",
  "EMERALD GARDENS",
  "PARKWAY (PHASE 4)",
  "16706 24 AVENUE TOWNHOMES",
  "13301 104 AVENUE CONDOS",
  "PANORAMA 30",
  "PANORAMA",
  "1724 KING GEORGE BOULEVARD TOWNHOMES",
  "18868 74 AVENUE TOWNHOMES",
  "18742 72 AVENUE CONDOS",
  "18495 72 AVENUE TOWNHOMES",
  "6051 KING GEORGE BOULEVARD TOWNHOMES",
  "5963 176 STREET CONDOS",
  "14328 104 AVENUE CONDOS",
  "108 AVENUE AND UNIVERSITY DRIVE CONDOS",
  "RIDGEVIEW ESTATES",
  "THE SKY ESTATES AT HIGHLINE METROTOWN",
  "THE AMAZING BRENTWOOD",
  "ECLIPSE BRENTWOOD",
  "REIGN - NORTH TOWER",
  "BASSANO BY BOFFO",
  "5502 LOUGHEED HIGHWAY CONDOS",
  "5650 BERESFORD STREET CONDOS",
  "KOEN",
  "CONCORD BRENTWOOD - PARKSIDE (PHASE 3)",
  "BURNABY LAKE HEIGHTS PHASE 1",
  "3819 & 3841 CANADA WAY CONDOS",
  "CONCORD METROTOWN (PHASE 2)",
  "SOUTHGATE VILLAGE",
  "3846 CARRIGAN COURT CONDOS",
  "2211 AND 2271 ROSSER AVENUE CONDOS",
  "4355 MAYWOOD STREET CONDOS",
  "AZURE AT SOUTHGATE CITY",
  "CIRCLE AT CONCORD BRENTWOOD",
  "6390 WILLINGDON AVENUE CONDOS",
  "BURNABY LAKE VILLAGE",
  "ONYX",
  "SOUTH YARDS (PHASE 4)",
  "ETHOS",
  "7330 SIXTH STREET CONDOS",
  "KOI",
  "2300 MADISON AVENUE CONDOS",
  "SYMPOSIA",
  "THE IDYLLIC",
  "4587 & 4545 JUNEAU STREET CONDOS",
  "4472 HASTINGS STREET CONDOS",
  "SOUTH YARDS (PHASE 2)",
  "7465 GRIFFITHS DRIVE CONDOS",
  "METROTOWN",
  "3676 BAINBRIDGE AVENUE CONDOS",
  "6508 TELFORD AVENUE CONDOS",
  "4370 - 4330 KINGSWAY CONDOS",
  "BURNABY LAKE HEIGHTS",
  "SOCO (PHASE 4)",
  "SOUTH YARDS (PHASE 3)",
  "3965 NORTH ROAD CONDOS",
  "O2 METROTOWN",
  "6444 SILVER AVENUE CONDOS",
  "VUE6",
  "MARILYN ON THE PARK",
  "VERA",
  "THE MERCHANT",
  "OPUS LANGLEY CONDO",
  "SPRING HILL",
  "HUNTER + WEST",
  "IBIS RESIDENCES",
  "19948 55A AVENUE CONDOS",
  "OXFORD MEWS",
  "SCALE",
  "FIELD + FORT",
  "BROOKSWOOD ESTATES",
  "20411 FRASER HIGHWAY CONDOS",
  "VIVID",
  "ELIJAH",
  "20659 - 20679 EASTLEIGH COURT CONDOS",
  "20297 FRASER HIGHWAY CONDOS",
  "FLORENCE ON FRASER",
  "20239 - 20249 54A AVENUE CONDOS",
  "5404-5414 207 STREET CONDOS",
  "20020 MICHAUD CRESCENT CONDOS",
  "19701-19729 55A AVENUE CONDOS",
  "5360 204 STREET CONDOS",
  "20199 53A AVENUE CONDOS",
  "20220 - 20230 MICHAUD COURT CONDOS",
  "19824 54 AVENUE TOWNHOMES",
  "20256 - 20272 54A AVENUE CONDOS",
  "5030 - 5064 208 STREET TOWNHOMES",
  "20300 DOUGLAS COURT CONDOS",
  "20719 - 20731 EASTLEIGH COURT CONDOS",
  "JERICHO PARK (PHASE 1)",
  "19653 - 19665 WILLOWBROOK DRIVE CONDOS",
  "BRIGHTON WEST ROWHOMES",
  "7620 197 STREET TOWNHOMES",
  "19752 80 AVENUE TOWNHOMES",
  "ARCHER AT LATIMER",
  "21406 & 21446 80 AVENUE TOWNHOMES",
  "THE MEADOWS",
  "7135 -7157 208 STREET CONDOS",
  "20792 68 AVENUE TOWNHOMES",
  "7883 199 STREET CONDOS",
  "7625 204 STREET CONDOS",
  "RIDGEMONT",
  "BLISS AT LATIMER",
  "JAMES HILL",
  "20326 & 20350 72 AVENUE TOWNHOMES",
  "MOMENTS",
  "6660 - 6788 200 STREET CONDOS",
  "20230 72B AVENUE CONDOS",
  "LATIMER WALK",
  "7860 197 STREET TOWNHOMES",
  "3111 272 STREET CONDOS",
  "19677 WILLOWBROOK DRIVE CONDOS",
  "7536 200 STREET CONDOS",
  "BRIGHTON WEST",
  "SAXON PARK",
  "20120 86 AVENUE CONDOS",
  "7687 200 STREET CONDOS",
  "WESTBROOKE AT WILLOUGHBY (PHASE 3)",
  "EVERGREEN - PHASE 2",
  "20186 AND 20212 86 AVENUE CONDOS",
  "7680 202A STREET TOWNHOMES",
  "22260 AND 22288 48 AVENUE CONDOS",
  "WALNUT PARK",
  "7449 200 STREET CONDOS",
  "MIRADA ESTATES",
  "EMORY",
  "EVERGREEN PHASE II",
  "8393 200 STREET TOWNHOMES",
  "20701 84 AVENUE STREET CONDOS",
  "7119-7143 207 STREET TOWNHOMES",
  "JERICHO - PHASE 3",
  "20320 80 AVENUE TOWNHOMES",
  "6920 204 STREET TOWNHOMES",
  "9148 GLOVER ROAD CONDOS",
  "20722 80 AVENUE CONDOS",
  "27257 & 27265 28A AVENUE CONDOS",
  "6705 & 6731 WILLOWBROOK CONNECTOR TOWNHOMES",
  "19886 76 AVENUE CONDOS",
  "21305 83 AVENUE TOWNHOMES",
  "27309 FRASER HIGHWAY CONDOS",
  "3321 200TH STREET TOWNHOMES",
  "21456 80 AVENUE TOWNHOMES",
  "7240, 7258 210TH STREET & 21031, 21081 72ND AVENUE TOWNHOMES",
  "7042 AND 7058 204 STREET TOWNHOMES",
  "7700 BLOCK OF 202A STREET TOWNHOMES",
  "21198 SMITH CRESCENT TOWNHOMES",
  "20700 BLOCK OF 80 AVENUE CONDOS",
  "3282 202 STREET TOWNHOMES",
  "7858 202A STREET TOWNHOMES",
  "19681 80 AVENUE TOWNHOMES",
  "AEGEAN STONE",
  "7573 AND 7595 203B STREET CONDOS",
  "19984 76 AVENUE CONDOS",
  "20701 84TH AVENUE TOWNHOMES",
  "7734 197 STREET TOWNHOMES",
  "7642 206 STREET CONDOS",
  "20615 67 AVENUE CONDOS",
  "20443 70 AVENUE CONDOS",
  "6815 204 STREET TOWNHOMES",
  "8393 200 STREET CONDOS",
  "20100 BLOCK OF 84 AVENUE TOWNHOMES",
  "20290 72 AVENUE CONDOS",
  "20145 84 AVENUE TOWNHOMES",
  "20309, 20339, 20347 68TH AVENUE & 6842, 6868 202B STREET TOWNHOMES",
  "ROWAN",
  "ZAIL WILLOUGHBY LANGLEY II",
  "20040 & 20056 40A AVENUE CONDOS",
  "20390 72 AVENUE CONDOS",
  "6869 210 STREET TOWNHOMES",
  "7434-7452 202A STREET TOWNHOMES",
  "20049 82 AVENUE CONDOS",
  "20390 72 AVENUE TOWNHOMES",
  "7033 204 STREET CONDOS",
  "6898 204 STREET TOWNHOMES",
  "7240 & 7258 210 STREET TOWNHOMES",
  "19942 78B AVENUE CONDOS",
  "20370 AND 20392 73A AVENUE CONDOS",
  "CARVOLTH 2",
  "20315 & 20323 78 AVENUE TOWNHOMES",
  "YORKSON SW TOWNHOMES",
  "7725 208 STREET TOWNHOMES",
  "20441 68 AVENUE TOWNHOMES",
  "7642 206 STREET TOWNHOMES",
  "20069 80 AVENUE CONDOS",
  "20347 68 AVENUE TOWNHOMES",
  "20876 76 AVENUE TOWNHOMES",
  "CARVOLTH TOWNHOMES",
  "20645 74B AVENUE TOWNHOMES",
  "2600 BLOCK OF 268TH STREET TOWNHOMES",
  "3134 200 STREET TOWNHOMES",
  "JERICHO - PHASE 2",
  "21200 BLOCK OF 83 AVENUE TOWNHOMES",
  "7130 202B STREET CONDOS",
  "20145 84 AVENUE CONDOS",
  "CULMENA",
  "NAUTILUS",
  "RUSSEL AND MAPLE",
  "COURTYARD COMMONS",
  "BEACHWAY 1",
  "14990 NORTH BLUFF ROAD CONDOS",
  "MIRACLE",
  "1453 STAYTE ROAD TOWNHOMES",
  "15409 BUENA VISTA AVENUE CONDOS",
  "THE MADISON AT HUNTER ROAD",
  "BOARDWALK - SALT AND MEADOWS 1",
  "BOARDWALK - SALT AND MEADOWS 2",
  "SCOTT + 77",
  "BRIDGE & ELLIOTT",
  "4726 - 4752 60B STREET TOWNHOMES",
  "WESTHAMPTON AT HAMPTON COVE",
  "8037 - 8087 120 STREET CONDOS",
  "DELTA CENTRAL 2",
  "DELTITA GARDENS",
  "LADNER SQUARE",
  "6115 HIGHWAY 17A CONDOS",
  "11199 84 AVENUE CONDOS",
  "THE SHELBY",
  "4876-4938 57 STREET TOWNHOMES",
  "CARMEL COLLECTION",
  "114 171 STREET TOWNHOMES",
  "IRONWOOD",
  "CHAPTER",
  "700-704 DELESTRE AVENUE CONDOS",
  "PARTINGTON CREEK",
  "THE HEIGHTS ON AUSTIN - EAST TOWER",
  "FORESTER AT BURKE MOUNTAIN",
  "THE ROBINSONS - PARKSIDE COLLECTION",
  "ASPEN BY ZAKO",
  "HARPER PEAK",
  "PORTHAVEN",
  "SOLANA",
  "MARY ANNE'S PLACE",
  "THE MANHATTAN",
  "INLET DISTRICT",
  "THE HIVE AT WILLOUGHBY TOWN CENTRE - PHASE 1",
  "KWASEN VILLAGE",
  "HEARTWOOD",
  "PINE AND GLEN (EAST TOWER)",
  "SOCO ONE",
  "SOLO 4",
  "FRANK",
  "RAVIR",
  "AMSON BLOC",
  "GABRIEL",
  "9075 152 STREET CONDOS",
  "RAPHAEL",
  "LATITUDE ON CAMBIE",
  "PERIDOT",
  "m/6",
  "ENZO",
  "ENTROTERRA YVR",
  "SOLID",
  "1688 ALBERNI STREET CONDOS",
  "2122 EAST HASTING STREET CONDOS",
  "LAMONT",
  "9850 AUSTIN ROAD CONDOS",
  "M RESIDENCE AND SPA",
  "BUCHANAN WEST",
  "5978 WILSON AVENUE CONDOS",
  "4141 LOUGHEED HIGHWAY CONDOS",
  "4404 PENDER STREET CONDOS",
  "3300 BOUNDARY ROAD CONDOS",
  "6378-6392 MCKAY AVENUE CONDOS",
  "7455 - 7495 ROYAL OAK AVENUE TOWNHOMES",
  "4180 LOUGHEED HIGHWAY CONDOS",
  "BRENTWOOD WEST PHASE 3",
  "7163 17TH AVENUE TOWNHOMES",
  "HIGHGATE TOWER",
  "9601 LOUGHEED HIGHWAY CONDOS",
  "6450 NELSON AVENUE CONDOS",
  "7201 11TH AVENUE CONDOS",
  "2330 DOUGLAS ROAD CONDOS",
  "4612 AND 4650 DAWSON STREET CONDOS",
  "BRENTWOOD WEST PHASE 4",
  "7343 BULLER AVENUE CONDOS",
  "5292 AND 5318 KINGSWAY CONDOS",
  "4214 MAYWOOD STREET CONDOS",
  "5000 KINGSWAY CONDOS",
  "BRENTWOOD WEST PHASE 1",
  "DOUGLAS TOWER BRENTWOOD",
  "4125 HASTINGS STREET CONDOS",
  "4410, 4438 JUNEAU STREET CONDOS",
  "3777 & 3791 KINGSWAY CONDOS",
  "7873 14TH AVENUE CONDOS",
  "SOUTHGATE VILLAGE - GATEWAY NEIGHBOURHOOD",
  "5777 WILLINGDON AVENUE CONDOS",
  "4444-4488 KINGSWAY CONDOS",
  "4700 AND 4800 KINGSWAY CONDOS",
  "6645-6707 DOW AVENUE CONDOS",
  "7320 CANADA WAY CONDOS",
  "7763 7767 EDMONDS STREET CONDOS",
  "5852 PATTERSON AVENUE CONDOS",
  "7236 AND 7248 SALISBURY AVENUE CONDOS",
  "6225 ROYAL OAK AVENUE CONDOS",
  "4411 HASTINGS STREET CONDOS",
  "6691 DOW AVENUE CONDOS",
  "6540-6592 TELFORD AVENUE CONDOS",
  "7109 18TH AVENUE CONDOS",
  "7544 BEVAN STREET TOWNHOMES",
  "CITY OF LOUGHEED",
  "6605 ROYAL OAK AVENUE CONDOS",
  "5044 & 5056 IMPERIAL STREET CONDOS",
  "HARRISWOOD",
  "KORA",
  "LEVEL",
  "WESTVIEW TOWNHOMES",
  "1160 INLET STREET CONDOS",
  "SOLSTICE",
  "SIERRA",
  "1038 1042 ALDERSON AVENUE CONDOS",
  "573 - 605 MADORE AVENUE TOWNHOMES",
  "513-566 BROOKMERE AVENUE CONDOS",
  "SIENNA",
  "3685 3695 VICTORIA DRIVE TOWNHOMES",
  "1230 MITCHELL STREET TOWNHOMES",
  "628-652 HARRISON AVENUE & 631-645 KEMSLEY AVENUE & 661 CLARKE ROAD CONDOS",
  "KESTREL",
  "SOCO (PHASE 3)",
  "909-927 GRANT STREET TOWNHOMES",
  "ENCORE",
  "717 EDGAR AVENUE TOWNHOMES",
  "3469 BAYCREST AVENUE TOWNHOMES",
  "550 COTTONWOOD AVENUE CONDOS",
  "3507 HALL AVENUE TOWNHOMES",
  "THOMPSON AVENUE TOWNHOMES",
  "SATORI",
  "SOCO - PHASE 5",
  "SYDNEY AVENUE TOWNHOMES",
  "OLO",
  "RIDGEWOOD",
  "817 EDGAR AVENUE TOWNHOMES",
  "SALISHAN - PHASE 3",
  "CORONATION HEIGHTS",
  "1364 - 1374 POLLARD STREET TOWNHOMES",
  "629 - 637 FOSTER AVENUE TOWNHOMES",
  "BEAUMONT",
  "SAILSHAN - PHASE 2",
  "200 MARMONT STREET CONDOS",
  "WELLWOOD",
  "MILLER AVENUE TOWNHOMES",
  "BAYCREST",
  "655 HARRISON AVENUE TOWNHOMES",
  "947 - 955 ROBINSON STREET CONDOS",
  "576 - 590 LOUGHEED HIGHWAY CONDOS",
  "WESTBURY",
  "TRICITY CENTRAL",
  "3625 BROWNLEE AVENUE TOWNHOMES",
  "565-575 KEMSLEY AVENUE CONDOS",
  "789-799 CLARKE ROAD CONDOS",
  "388 BLUE MOUNTAIN STREET TOWNHOMES",
  "727 NORTH ROAD CONDOS",
  "4200 DEWDNEY TRUNK ROAD CONDOS",
  "2929 BARNET HIGHWAY CONDOS",
  "1033 - 1035 JAMES AVENUE CONDOS",
  "831 EDGAR AVENUE TOWNHOMES",
  "566 LOUGHEED HIGHWAY CONDOS",
  "1369 COAST MERIDIAN ROAD TOWNHOMES",
  "3633 VICTORIA DRIVE TOWNHOMES",
  "739 ROBINSON STREET CONDOS",
  "NEW VILLAGE",
  "604-608 SYDNEY AVENUE TOWNHOMES",
  "3580 VICTORIA DRIVE TOWNHOMES",
  "900 ROBINSON TOWNHOMES",
  "2800 BARNET HIGHWAY CONDOS",
  "1036 ALDERSON CONDOS",
  "BURQUITLAM PLAZA",
  "CLARKE ROAD & HOBBIS WAY CONDOS",
  "COMO LAKE",
  "541 CLARKE ROAD CONDOS",
  "MINNEKHADA",
  "544 AUSTIN AVENUE CONDOS",
  "711 - 729 DOGWOOD STREET TOWNHOMES",
  "597, 605, 613, 621 FOSTER AVENUE TOWNHOMES",
  "TRICITY CENTRAL - PHASE 1",
  "597 THOMPSON AVENUE TOWNHOMES",
  "792 - 800 CLARKE ROAD TOWNHOMES",
  "1034 WESTWOOD STREET CONDOS",
  "3188 SHAUGHNESSY STREET CONDOS",
  "TOWN AND CENTRE - NORTH TOWER",
  "TOWN AND CENTRE - SOUTH TOWER",
  "2228 KELLY AVENUE CONDOS",
  "850 VILLAGE DRIVE CONDOS",
  "RINDALL AVENUE & TYNER STREET TOWNHOMES",
  "1290 WELLON STREET TOWNHOMES",
  "2151 TYNER STREET TOWNHOMES",
  "2245 MCALLISTER AVENUE CONDOS",
  "2279 KELLY AVENUE CONDOS",
  "2431 MARY HILL ROAD CONDOS",
  "2387 & 2399 ATKINS AVENUE CONDOS",
  "2634 2638 KINGSWAY AVENUE CONDOS",
  "1781 PRAIRIE AVENUE TOWNHOMES",
  "2149-2159 GRANT AVENUE CONDOS",
  "2352 SHAUGHNESSY STREET CONDOS",
  "THE MET",
];

export default projects;
